@import url("https://fonts.googleapis.com/css2?family=Bangers&family=Lusitana:wght@400;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* React Date Picker  */
.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__inputGroup__input {
  outline: none !important;
}
.react-date-picker__inputGroup__day::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.8) !important;
}
.react-date-picker__inputGroup__month::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.8) !important;
}
.react-date-picker__inputGroup__year::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.8) !important;
}
.react-date-picker__inputGroup__input:invalid {
  background: none !important;
}
.react-date-picker__inputGroup__divider {
  color: rgba(0, 0, 0, 0.8) !important;
}
.react-date-picker__calendar {
  position: absolute !important;
  height: 0 !important;
  top: 40px !important;
}
.react-calendar {
  background-color: rgb(255, 255, 255) !important;
}
.react-calendar__tile--now {
  background-color: rgb(38, 170, 170) !important;
}
.react-calendar__tile:hover {
  background-color: #c2bbbb !important;
}
.errors-text-color .swal-text {
  color: red !important;
}

.description div,
p {
  display: inline;
}
